<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'生产商管理'" :line="true" />
      <CommonHeaders
        :formConfig="formConfig"
        :formModel="formModel"
        :buttons="buttons"
        @button-click="handleButtonClick"
      ></CommonHeaders>
    </div>
    
    <div class="commonControl-body">
      <!-- <el-button type="primary" @click="addFormButton()">新增生产商</el-button> -->
      <common-table
        :tableData="tableData"
        :tableButton="tableButton"
        :tableLabel="tableLabel"
        @edit="editFirstLevel"
      ></common-table>
      <div class="layoutBorder">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑生产商" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="生产商id" label-width="120px">
          <el-input v-model="formEdit.manufacturer_id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
       
        <el-form-item label="生产商名称" label-width="120px">
          <el-input v-model="formEdit.production_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增生产商" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="生产商code" label-width="120px">
          <el-input v-model="formAdd.production_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="生产商名称" label-width="120px">
          <el-input v-model="formAdd.production_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  manufacturer_get,
  manufacturer_create,
  manufacturer_update,
  manufacturer_delete
} from "@/api/manage/operation/agv/manufacturer.js";
import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
import CommonHeaders from "@/components/shibo_Common/CommonHeaders.vue";

export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
      },
      //分页数据
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "id",
          minWidth:"50%"
          // position:"right"
        },
    
        {
          prop: "production_code",
          label: "厂商编号",
          minWidth:"50%"
        },
        {
          prop: "production_name",
          label: "生厂商名称",
          minWidth:"50%"
          
        },
      ],
      tableButton: {
        width:300,
        data:[{
          name: "编辑",
          type: "primary",
          size: "small",
        },
      
        {
          name: "删除",
          type: "danger",
          size: "small",
        },]
      },
      value: null,
      optionValue: null,
      formConfig: [],
      formModel: {},
      buttons: [
        { label: "新增生产商", type: "primary",action:'create' },
      ]
    };
  },
  components: {
    CommonTable,
    CommonHeaders
  },
  created() {
    this.getFirstLevel();
    this.initOption()
  },
  methods: {
    initOption() {
      // product_classify_get({}).then((res) => {
      //   if (res.code == 200) {
      //     this.value = res.data;
      //   } else {
      //     this.$message.info(res.msg);
      //   }
      // });
    },
    editFirstLevel(row, methods) {
      if (methods == "编辑") {
        this.editUser(row);
      }
      if (methods == "编辑附件") {
        this.gotoUser(row);
      }
      if (methods == "删除") {
        this.delUser(row);
      }
    },
    gotoUser(row) {
      this.formEdit = {
        group_id: row.id,
        group_name: row.group_name,
        group_info: row.group_info,
      };
      console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
      row;
      // this.$router.push({ name: "productLibraryInfo", query:   row  });
    },
    editUser(row) {
      this.formEdit = {
        manufacturer_id: row.id,
        production_name: row.production_name,
      };
      // console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
      // row;
      // this.$router.push({ name: "productLibraryInfo", query: { id: row.id } });
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          manufacturer_delete({
            manufacturer_id: ids, 
            
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              window.location.reload();
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      manufacturer_get(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      console.log(this.query);
      manufacturer_get(this.query).then((res) => {
        console.log(this.tableData, "new");
        this.tableData = res.data;
      });
    },

    createFirstLevel() {
      manufacturer_create(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          this.getFirstLevel()
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
     
      console.log(this.formEdit);
      manufacturer_update(this.formEdit).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功修改");
          this.getFirstLevel()
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    },
    getFirstLevel() {
      manufacturer_get(this.query).then((res) => {
        
        this.tableData = res.data;
        console.log(this.tableData, "new");
      });
    },
    handleButtonClick(button){
      if (button.action === "create") {
        this.addFormButton();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>






