import request from '@/utils/request'

// 一级-生产商-查询
export function manufacturer_get(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/manufacturer/query',
        method: 'post',
        data: data
    })
}

// 一级-生产商-新建
export function manufacturer_create(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/manufacturer/create',
        method: 'post',
        data: data
    })
}

// 一级-生产商-更新
export function manufacturer_update(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/manufacturer/update',
        method: 'post',
        data: data
    })
}
// 一级-生产商-删除
export function manufacturer_delete(data) {
    return request({
        url: '/platform_api/manage_api/agv_manage/manufacturer/delete',
        method: 'post',
        data: data
    })
}